.procursys-searchContainer {
  position: absolute !important;
  top: 0;
  left: 46px;
  width: 400px;
  height: 34px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.procursys-searchBox {
  width: 100%;
  height: 30px;
  padding: 2px 30px 2px 10px;
  border: none;
  overflow: hidden;
  outline: none;
  background-color: white;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.procursys-searchBox:focus,
.procursys-searchBox:focus + .procursys-clearButton {
  background-color: #f4f4f4;
}

.procursys-clearButton {
  top: 0;
  right: 0;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  width: 30px;
  border: none;
  background-color: white;
  outline: none;
  vertical-align: middle;
  font-size: 16px;
  color: grey;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.procursys-clearButton:hover {
  color: MidnightBlue;
}

@media screen and (max-width: 1200px) {
  .procursys-searchContainer {
    width: 300px;
  }
}

@media screen and (max-width: 600px) {
  .procursys-searchContainer {
    width: 200px;
  }
}
