/* KendoReact styles variables */
$primary: #1b1c51;
$secondary: #f36a22;
$vertical-line-color: rgba(0, 0, 0, 0.06);
$grid-header-font-size: 14px;

/* KendoReact styles */
@import "~@progress/kendo-theme-material/scss/grid/_index.scss";

/* KendoReact styles overrides */
.k-grid {
  a {
    color: $primary;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
  th, td {
    border-left-color: $vertical-line-color;
    padding: 10px 12px;
  }
}
.k-grid-header .k-grid-filter {
  padding: 4px;
  height: 24px;
  width: 24px;
}
.k-grid-header .k-filterable {
  padding-right: 36px;
}
.k-column-resizer {
  z-index: 1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Overrides for FirebaseUI */
.firebaseui-container .firebaseui-idp-list {
  margin: 0;
}

/* Overrides for FreshChat */
@media(max-width: 959px) {
  #fc_frame, #fc_frame.fc-widget-small, #fc_frame.fc-widget-normal {
    bottom: 65px !important;
  }
}

/* Overrides for CKEditor */
.ck-editor__editable_inline {
  min-height: 100px;
}
.ck-body-wrapper {
  --ck-z-default: 1;
  /* Fix CKEditor modals displayed with a lower z-index than Material UI modals */
  --ck-z-modal: calc(var(--ck-z-default) + 9999);
}

.pac-container {
  /* making google autocomplete above modal window */
  z-index: 1400;
}

/*
 * Override the z-index for pseudo fullscreen mode
 * from <https://github.com/Leaflet/Leaflet.fullscreen>
 */
.leaflet-pseudo-fullscreen {
  /*
   * Default z-indexes in Material-UI: <https://material-ui.com/customization/z-index/#z-index>
   * - drawers = 1200
   * - modals = 1300
   * We want fullscreen maps be behind modals, snackbars, and tooltips,
   * but in front of app bars and everything below.
   */
  z-index: 1209 !important;
}


/**
  Override browser update styles
  from <https://browser-update.org/customize.html>
 */

.buorg {
  border: 1px solid #dbdbdb !important;
  color: rgb(102, 60, 0) !important;
  background-color: rgb(255, 244, 229) !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.43 !important;
  border-radius: 4px !important;
  letter-spacing: 0.01071em !important;
  box-shadow: none !important;
  animation: none !important;
}

.buorg-pad {
  padding: 9px;
  line-height: 1.7em;
}

.buorg-buttons {
  margin-top: 0.5rem;
  > a {
    color: #ffffff !important;
    background-color: #1b1c51 !important;
    text-transform: uppercase;
    padding: 3px 16px !important;
    font-size: 0.875rem !important;
    font-weight: 500;
    &:hover {
      background-color: #4d4e9d !important;
    }
  }
}
.buorg-moremsg {
  display: block;
}
#buorgig {
  background-color: #f36a22 !important;
  &:hover {
    background-color: rgb(170, 74, 23) !important;
  }
}

// column menu should be over sidebar
.k-animation-container {
  z-index: 1300 !important;
}

.ReactVirtualized__Table__rowColumn {
  text-overflow: inherit !important;
  white-space: normal !important;
}
