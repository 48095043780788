.marker-cluster-small {
  background-color: rgba(64, 64, 146, 0.4);
}
.marker-cluster-small div {
  background-color: rgba(64, 64, 146, 0.7);
}

.marker-cluster-medium {
  background-color: rgba(28, 28, 81, 0.4);
}
.marker-cluster-medium div {
  background-color: rgba(28, 28, 81, 0.7);
}

.marker-cluster-large {
  background-color: rgba(8, 8, 31, 0.4);
}
.marker-cluster-large div {
  background-color: rgba(8, 8, 31, 0.7);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}
.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  font: bold 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: white;
}
.marker-cluster span {
  line-height: 30px;
}
