$offset-top: 100px;
$channels-list-header-height: 133px;
$accent-color: #fbbc04;
$my-message-bg: #c8dbff;
$coworker-message-bg: #c6cdde;

// Override default stream styles
.str-chat__channel {
    width: 100%;
}

.str-chat {
    --str-chat__message-max-width: 1000;
    height: 100%;

    &__channel-list {
        width: 470px;
    }

    &__list {
        background: url(../../assets/chat_bg.png) !important;
        background-size: 950px !important;
    }

    &__channel-list-messenger__main {
        height: calc(100vh - #{$offset-top} - #{$channels-list-header-height});
    }

    &__message--other {
      .str-chat__message-bubble {
        background-color: rgb(245, 250, 255) !important;
      }
    }

    &__message-simple--me {
        .str-chat__message-bubble {
            background-color: $my-message-bg !important;
        }
    }

    &__message--coworker {
        .str-chat__message-bubble {
            background-color: $coworker-message-bg !important;
        }
    }

    .str-chat__channel-preview-messenger--last-message {
        max-width: 250px;
        > p {
            max-width: 250px;
            white-space: pre;
        }
    }

    &__message-simple-text-inner {
        max-width: 1000px;
    }

    &__message-input {
        padding-bottom: 14px;
    }

    &__loading-channels {
        height: 80%;
    }

    &__header-livestream {
        box-shadow: none !important;
    }

    &__main-panel {
        padding-top: 0 !important;
        padding-right: 0 !important;
    }

    &__thread {
        margin: 0 !important;
        border-radius: 0 !important;
        &-header {
            height: 110px
        }
    }

    &__send-button {
        display: block;
        cursor: pointer;
    }

    &__avatar {
        background: inherit;
        &-image--loaded {
            object-fit: contain !important;
        }
    }

    &__emojiselect-wrapper {
        .str-chat__tooltip {
            z-index: 1;
        }
        .str-chat__input-flat-emojiselect {
            z-index: 1;
            top: calc(100% - 48px);
        }
    }
}

.str-chat-sidebar {
  .str-chat {
    height: calc(100vh - 87px) !important;
    overflow-y: hidden;

    &__input-flat {
        padding: 8px 8px 20px 8px;
    }

    &__message-simple-text-inner {
        max-width: 400px;
    }

    &__loading-channels {
        height: 80%;
    }

    &__header-livestream {
        box-shadow: none !important;
    }

    &__main-panel {
        padding-top: 0 !important;
        padding-right: 0 !important;
    }

    &__thread {
        margin: 0 !important;
        border-radius: 0 !important;
        &-header {
            height: 110px
        }
    }

    &__send-button {
        display: block;
        cursor: pointer;
    }

    &__avatar {
        background: #c3c3c3;
        &-image--loaded {
            object-fit: contain !important;
        }
    }

    &__emojiselect-wrapper {
        .str-chat__tooltip {
            z-index: 1;
        }
        .str-chat__input-flat-emojiselect {
            z-index: 1;
            top: calc(100% - 48px);
        }
    }
  }
}
